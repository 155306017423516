import { ref, reactive, computed } from 'vue';
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { PendingApproveResponse, ApproveStatusResponseItem, BatchTrackingPackage, BatchTrackingQueryParams, ProgramTrackingQueryParams, PendingApprovePackage, PackageApproveRequestBodyItem } from "@/views/Approve/MgRoleApprove/PackageHome/types";
import { getMgPendingApprove, approvePackage } from "@/API/approve";
import { useStore } from 'vuex';
import useFetch from "@/hooks/useFetch";
type Key = ColumnProps['key'];
const useManagementApproval = () => {
    const {commit, state} = useStore()
    const _w = window.screen.width / 375
    const pendingColumn = ref([
        {
            title: 'Batch Name',
            dataIndex: 'batchName',
            slots: { customRender: 'batchName' },
            width: _w * 200,
        },
        {
            title: 'Total Budget',
            dataIndex: 'totalBudget',
            slots: { customRender: 'totalBudget' },
            align: "right",
            width: _w * 100,
        }
        
    ])
    const { data: pedingTableData, refreshFetch } = useFetch<PendingApproveResponse>({}, {
        fleetSales: [],
        normalSales: []
    }, getMgPendingApprove, false, (res: PendingApproveResponse): void => {
        // 存储PendingApproveCount
        commit('approve/updatePendingApproveCount', res.fleetSales.length + res.normalSales.length);
    });

    const approvalModalVisible = ref<boolean>(false);
    const modalVisible = ref<boolean>(false);
    const modalMessage = ref<string>('')
    const showMessage = (message: string) => {
        modalMessage.value = message
        modalVisible.value = true
    }


    const normalTableSelection = reactive<{
        selectedRowKeys: Key[];
        selectedRowData: PendingApprovePackage[];
        onChange: Function;
        columnWidth: string | number;
      }>({
        onChange: (selectedRowKeys: string[], selectedRows: PendingApprovePackage[]) => {
            normalTableSelection.selectedRowKeys = selectedRowKeys
            normalTableSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: 0
      });
      const fleetTableSelection = reactive<{
        selectedRowKeys: Key[];
        selectedRowData: PendingApprovePackage[];
        onChange: Function;
        columnWidth: string | number;
      }>({
        onChange: (selectedRowKeys: string[], selectedRows: PendingApprovePackage[]) => {
            fleetTableSelection.selectedRowKeys = selectedRowKeys
            fleetTableSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: 0
      });

      const selectedRowData = computed(() => [...normalTableSelection.selectedRowData, ...fleetTableSelection.selectedRowData])

      const loading = ref(false)
      const actionType = ref<string>("approve");



      const resetTableSelectData = (): void => {
            normalTableSelection.selectedRowKeys = [];
            normalTableSelection.selectedRowData = [];
            fleetTableSelection.selectedRowKeys = [];
            fleetTableSelection.selectedRowData = [];
        }
      const handleCommentsConfirm = (comment: string, next: Function) => {
        const closeModal = () => {
          approvalModalVisible.value = false
          next()
        //   clearSelection()
        }
    
        loading.value = true
        const requestBody: PackageApproveRequestBodyItem[] = [];
        normalTableSelection.selectedRowData.concat(fleetTableSelection.selectedRowData as any).forEach(item => {
            requestBody.push({
                batchId: item.batchId,
                approvalSatus: actionType.value,
                content: comment
            });
        })
        approvePackage(requestBody).then(data => {
            refreshFetch()
            closeModal()
            showMessage(actionType.value === 'approved' ?  'Approved Successfully!' : 'Rejected Successfully')
            resetTableSelectData()
            loading.value = false
        }).catch(() => {
            loading.value = false
            showMessage(actionType.value === 'approved' ?  'Approve Failed!' : 'Reject Failed!')
        })
      }
    
    return {
        pendingColumn,
        pedingTableData,
        modalMessage,
        modalVisible,
        normalTableSelection,
        fleetTableSelection,
        actionType,
        loading,
        handleCommentsConfirm,
        approvalModalVisible,
        selectedRowData
    }
}
export default useManagementApproval;